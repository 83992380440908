import { db } from "../../firebase";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";
import swal from "sweetalert2";

const reactivateAccount = async (userId, t) => {
  try {
    const docRef = doc(db, "suspendedAccount", userId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists() && docSnap.data().suspended) {
      swal
        .fire({
          title:
            "Your account has been suspended, please reactive your subscription by clicking on Continue",
          showCancelButton: true,
          confirmButtonText: "Continue",
          showLoaderOnConfirm: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const reactivate = httpsCallable(functions, "reactivateAccount");
            return reactivate().then((res) => {
              if (res.data.error) {
                swal.fire(t("swal.1"), t("swal.9"), "error");
              }
            });
          } else if (result.isConfirmed === false) {
            signOut(auth);
          }
        })
        .catch((err) => {
          swal.fire(t("swal.1"), t("swal.9"), "error");
          console.log(err);
        });
    }
  } catch (error) {
    console.log("error", error);
  }
};

export default reactivateAccount;
