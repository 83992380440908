import {
  setLoading,
  setOwnerValue,
  setProjectValue,
  setEvaluations,
  setProjectStatus,
} from "../leadsSlice";
import { statusFormatter } from "../../../../utils/statusFormatter";
export const onOwnerChangeAction = (e) => {
  return async (dispatch, getState) => {
    const { All } = getState().leads;
    const value = e.value;
    dispatch(setLoading(true));
    dispatch(setOwnerValue(e));
    dispatch(setProjectValue(null));

    if (value !== "all") {
      const evals = All.filter(
        (v) => v?.estProprietaireReponse?.toLowerCase() === value?.toLowerCase()
      );
      dispatch(setEvaluations(evals));

      if (value === "oui") {
        const checkOne = evals.map((v) => v.envisageVendreBienReponse);

        const projectStatus = statusFormatter(checkOne);
        dispatch(setProjectStatus(projectStatus));
        dispatch(setLoading(false));
      } else {
        const checkTwo = evals.map((v) => v.statutRecherche);
        const projectStatus = statusFormatter(checkTwo);

        dispatch(setProjectStatus(projectStatus));
        dispatch(setLoading(false));
      }
    } else {
      dispatch(setEvaluations(All));
      dispatch(setLoading(false));
    }
  };
};
