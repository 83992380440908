import moment from "moment";

const verifyAbilityToAccept = (
  dejaAcceptedAContact,
  lastAcceptedContactDate
) => {
  const dejaAccepted = dejaAcceptedAContact && dejaAcceptedAContact >= 2;
  const isRestricted = dejaAccepted
    ? lastAcceptedContactDate &&
      moment(lastAcceptedContactDate).isAfter(moment().subtract(4, "hours"))
    : lastAcceptedContactDate &&
      moment(lastAcceptedContactDate).isAfter(moment().subtract(15, "minutes"));

  return isRestricted;
};

export default verifyAbilityToAccept;
