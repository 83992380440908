import { collection, addDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import { setResult, setFormalizedData } from "../evaluationSlice";

const postEstimation = (
  user,
  userData,
  formalizedData,
  result,
  navigate,
  updateLoading
) => {
  return async (dispatch, getState) => {
    try {
      if (updateLoading) {
        updateLoading(true);
      }
      const ficheEstimation = {
        ...result,
        ...formalizedData,
        userID: user,
        userEmail: userData?.email,
        phoneNumber: userData?.phoneNumber,
        displayName: userData?.displayName,
        duplicated: true,
        createdAt: new Date(),
      };

      const res = await addDoc(
        collection(db, "RapportsEvaluations"),
        ficheEstimation
      );
      dispatch(setResult({}));
      dispatch(setFormalizedData({}));
      navigate("/vmz/" + res?.id);
      if (updateLoading) {
        updateLoading(false);
      }
    } catch (error) {
      console.log("postEstimation_postEstimation", error);
      if (updateLoading) {
        updateLoading(false);
      }
    }
  };
};
export default postEstimation;
