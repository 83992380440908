import { useSelector } from "react-redux";
import ManageSubscription from "./components/ManageSubscription";
import "./style.scss";

const Abonnement = () => {
  const { accountData, loadingUserData, loadingUserAccount } = useSelector(
    (state) => state.authentication
  );
  return (
    <>
      <div>
        <ManageSubscription
          loading={loadingUserData && loadingUserAccount}
          currentAccount={accountData}
        />
      </div>
    </>
  );
};

export default Abonnement;
