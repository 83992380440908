import { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Spinner from "../../../components/spinner";
import { createStripeToken } from "../../../redux/slices/subscription/actions/createStripeToken";
import { updateSubscription } from "../../../redux/slices/subscription/actions/updateSubscription";
import swal from "sweetalert2";

const PaymentStripe = ({ selectedPack, previousStep, currentPlan, goBack }) => {
  const [cardNo, setCardNo] = useState("");
  const [mmyy, setMmyy] = useState("");
  const [fullName, setFullname] = useState("");
  const [cvv, setCvv] = useState("");
  const [discount, setDiscount] = useState(0);
  const [coupon, setCoupon] = useState("");
  const [loading, setLoading] = useState(false);

  let taxAmount = useMemo(() => 0, []);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { taxes, cards, selectedCardId } = useSelector(
    (state) => state.subscription
  );

  const [selectedCard, setSelectedCard] = useState(selectedCardId || null);

  const subscription = useSelector((state) => state.subscription);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let selectedPackage = useMemo(() => selectedPack, [selectedPack]);

  const makePayment = useCallback(
    async (e) => {
      e.preventDefault();
      if (loading) return;

      if (!selectedCard && !cardNo) return alert(t("NProfile.129"));
      if (!selectedCard && !mmyy) return alert("expiry date is required");
      if (!selectedCard && !cvv) return alert("cvv is required");

      setLoading(true);
      let data = null;
      if (!selectedCard) {
        data = await createStripeToken({
          number: cardNo,
          exp_month: mmyy.split(" ")[0],
          exp_year: mmyy.split(" ")[1],
          cvc: cvv,
        });
      }

      dispatch(
        updateSubscription(
          {
            user: subscription.current,
            source: data ? data.id : "",
            price: selectedPackage.id,
            planName: selectedPackage.name,
            coupon,
            card: selectedCard,
            selectedPlanId: selectedPackage.id,
          },
          async (data) => {
            setLoading(false);
            goBack();
            await swal.fire({
              titleText: t("profile.subscription.payment.13"),
              showCancelButton: false,
              confirmButtonText: "Okay",
              showLoaderOnConfirm: true,
              allowOutsideClick: () => !swal.isLoading(),
            });
          }
        )
      );
    },
    [
      cardNo,
      cvv,
      dispatch,
      mmyy,
      selectedCard,
      selectedPackage,
      subscription,
      coupon,
      t,
      goBack,
      loading,
    ]
  );

  return (
    <>
      {loading && <Spinner />}
      <div className="payemntStripe-container">
        <div className="selected-plan-snippet">
          <div className="selected-plan-name-container">
            <div>
              <img
                src={
                  selectedPackage?.productMetadata[
                    `${selectedPackage?.recurring?.interval}_img`
                  ]
                }
                alt="icon"
              />
              <p className="component-title">
                {t(
                  `Sign_up.${selectedPackage.name} - ${selectedPackage?.recurring?.interval}`
                )}
              </p>
            </div>
            <p>
              $
              {(selectedPackage?.unit_amount / 100)
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </p>
          </div>
          {taxes?.length &&
            taxes.map((tax) => {
              taxAmount += selectedPackage
                ? (tax.percentage / 100) *
                  (selectedPackage.unit_amount / 100 - discount)
                : 0;
              return (
                <div className="taxes-info" key={tax?.id}>
                  <p>
                    {tax.display_name} ({tax.percentage}%)
                  </p>
                  <p>
                    CA$
                    {selectedPackage
                      ? (
                          (tax.percentage / 100) *
                          (selectedPackage.unit_amount / 100 - discount)
                        )
                          .toFixed(2)
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : 0}
                  </p>
                </div>
              );
            })}
          <div className="plan-total">
            <p>{t("NProfile.115")}</p>
            <p className="White-txt font-bold">
              CA$
              {selectedPackage
                ? (selectedPackage.unit_amount / 100 + taxAmount - discount)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0}
            </p>
          </div>
        </div>
        <div className="payment-form-container">
          {cards.length &&
            cards.map((card) => (
              <div
                className="single-card-container"
                key={card?.id}
                style={{
                  borderWidth: card.id === selectedCard ? 1 : 0,
                  borderColor: "#5F3994",
                }}
              >
                <div className="flex-row">
                  <img
                    src={
                      card.brand === "Visa"
                        ? "./assets/images/visa.png"
                        : "./assets/images/master-card.png"
                    }
                    alt="card_icon"
                  />
                  <div>
                    <p className="card-number">
                      {t("profile.subscription.payment.1")} {card.brand} XXXX{" "}
                      {card.last4}
                    </p>
                    <p className="card-number card-expires-on">
                      {t("profile.subscription.payment.2")} {card.exp_month}/
                      {card.exp_year}
                    </p>
                  </div>
                </div>
                {selectedCard !== card.id && (
                  <button
                    className="bg-filled-btn"
                    onClick={() => setSelectedCard(card.id)}
                  >
                    {t("profile.subscription.payment.3")}
                  </button>
                )}
              </div>
            ))}
          {!selectedCard ? (
            <div>
              <div className="new-card-txt">
                <p>{t("profile.subscription.payment.4")}</p>
                <div />
              </div>
              <div className="name">
                <label>{t("profile.subscription.payment.5")}</label>
                <input
                  className="card-inputs mb-20"
                  id="username"
                  onChange={(e) => setFullname(e.target.value)}
                  value={fullName}
                />
              </div>
              <div>
                <label>{t("profile.subscription.payment.6")}</label>
                <div className="name-input-container">
                  <input
                    className="card-inputs card-number"
                    id="card-no"
                    maxLength="19"
                    onChange={(e) =>
                      setCardNo(
                        e.target.value
                          .replace(/\W/gi, "")
                          .replace(/(.{4})/g, "$1 ")
                          .trim()
                      )
                    }
                    placeholder="1234 1234 1234 1234"
                    value={cardNo}
                  />
                  <img
                    src="./assets/images/cards.png"
                    className="cards-img"
                    alt="card"
                  />
                </div>
                <div className="expiry-cvv-container">
                  <input
                    className="mm-yy"
                    id="mmyy"
                    maxLength="5"
                    onChange={(e) =>
                      setMmyy(
                        e.target.value
                          .replace(/\W/gi, "")
                          .replace(/(.{2})/g, "$1 ")
                          .trim()
                      )
                    }
                    placeholder="MM/YY"
                    value={mmyy}
                  />
                  <input
                    className="mm-yy"
                    id="cvv"
                    placeholder="CVV"
                    minLength="3"
                    onChange={(e) => setCvv(e.target.value)}
                    value={cvv}
                  />
                  <img
                    src="./assets/images/cvv.png"
                    className="cvv-img"
                    alt="ccv_icon"
                  />
                </div>
              </div>
            </div>
          ) : (
            <p className="new-card-btn" onClick={() => setSelectedCard(null)}>
              {t("profile.subscription.payment.7")}
            </p>
          )}
          {currentPlan !== selectedPackage?.id ? (
            <button className="payer" onClick={makePayment}>
              {loading
                ? t("NProfile.120")
                : "Payer $" +
                  (selectedPackage?.unit_amount / 100 + taxAmount - discount)
                    ?.toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </button>
          ) : (
            <button className="your-current-plan">Plan actuel </button>
          )}
        </div>
      </div>
    </>
  );
};

export default PaymentStripe;
