import "../layout.scss";
import { useSelector } from "react-redux";
import Header from "../components/Header";
import SideNav from "../components/SideNav";
import { classList } from "../../../utils/classList";
import Footer from "../components/footer";

import ScrollToTop from "../components/ScrollToTop";
import WarningMessage from "../components/WarningMessage";

const AuthLayout = ({ children, noFooter }) => {
  const { leftSidebar } = useSelector((state) => state.layoutSetting);
  const { accountData } = useSelector((state) => state.authentication);

  return (
    <div>
      <div
        className={`${classList({
          "app-admin-wrap layout-sidebar-large": true,
          "sidenav-open": leftSidebar.open,
        })} sidebar-dark-purple`}
      >
        <ScrollToTop />
        <Header />
        <SideNav />

        <div
          className={classList({
            "main-content-wrap d-flex flex-column": true,
            "sidenav-open": leftSidebar.open,
          })}
        >
          <div className="main-content">
            <WarningMessage accountData={accountData} />

            {children}
          </div>
          {!noFooter && <Footer />}
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
