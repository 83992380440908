import { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useDispatch } from "react-redux";

const Timer = ({
  lastAcceptedContactDate,
  setIsRestricted,
  dejaAcceptedAContact,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentTime = useMemo(
    () =>
      dejaAcceptedAContact >= 2
        ? new Date().getTime() - 4 * 60 * 60 * 1000
        : new Date().getTime() - 15 * 60 * 1000,
    [dejaAcceptedAContact]
  );

  const lastAcceptedContactTime = useMemo(
    () => lastAcceptedContactDate,
    [lastAcceptedContactDate]
  );

  const [remainingTime, setRemainingTime] = useState(null);

  useEffect(() => {
    setRemainingTime(lastAcceptedContactTime - currentTime);
  }, [currentTime, lastAcceptedContactTime]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime((prev) => prev - 1000);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const convertMillisecondsToTime = useCallback(
    (milliseconds) => {
      const duration = moment.duration(milliseconds, "milliseconds");
      return dejaAcceptedAContact >= 2
        ? `${String(duration.hours()).padStart(2, "0")}:${String(
            duration.minutes()
          ).padStart(2, "0")}:${String(duration.seconds()).padStart(2, "0")}`
        : `${String(duration.minutes()).padStart(2, "0")}:${String(
            duration.seconds()
          ).padStart(2, "0")}`;
    },
    [dejaAcceptedAContact]
  );

  useEffect(() => {
    if (remainingTime && remainingTime <= 0) {
      dispatch(setIsRestricted(false));
    }
  }, [remainingTime, dispatch, setIsRestricted]);

  return (
    <div className="newlead_timer">
      <span>{t("Leads.124")}</span>

      <span className="newlead_timer_time">
        {convertMillisecondsToTime(remainingTime)}
      </span>
    </div>
  );
};
export default Timer;
