import { useState, useEffect, useCallback, useMemo } from "react";
import Plan from "./Plan";
import { useTranslation } from "react-i18next";

const AllPlans = (props) => {
  const { t } = useTranslation();
  const { products, currentPlan, setCurrStep, setSelectedPack } = props;
  const [performanceProducts, setPerformanceProducts] = useState([]);

  const monthlyBenifitsList = useMemo(() => {
    return [
      "Sign_up.benefits.monthly.1",
      "Sign_up.benefits.monthly.2",
      "Sign_up.benefits.monthly.3",
    ];
  }, []);

  const yearlyBenifitsList = useMemo(() => {
    return [
      "Sign_up.benefits.yearly.1",
      "Sign_up.benefits.yearly.2",
      "Sign_up.benefits.yearly.3",
      "Sign_up.benefits.yearly.4",
      "Sign_up.benefits.yearly.5",
    ];
  }, []);

  const sortPlans = useCallback((plans) => {
    const filterPerformancePlan = plans.filter(
      (plan) => plan.name === "Performance"
    );
    const sortedPlans = filterPerformancePlan.sort((a, b) => {
      if (a.unit_amount > b.unit_amount) return 1;
      if (a.unit_amount < b.unit_amount) return -1;
      return 0;
    });
    return sortedPlans;
  }, []);

  useEffect(() => {
    if (products?.length > 0) {
      let performanceP = products?.filter((p) => p?.name === "Performance");
      performanceP = performanceP.map((product) => {
        const imgName = `${product?.recurring?.interval}_img`;
        return {
          ...product,
          video: product.productMetadata.video,
          priceImage: product.productMetadata[imgName],
          discount: product?.recurring?.interval === "year" ? "15%" : null,
        };
      });

      setPerformanceProducts(performanceP);
    }
  }, [products, sortPlans]);

  return (
    <>
      <div className="card-container">
        <div className="row-spaceBtwn all-plans-tab-view">
          <p className="component-title">
            {t("profile.subscription.all_plans.1")}
          </p>
          <div className="width-140" />
        </div>
        <div className="all-plans-container">
          {performanceProducts.map((pack, key) => (
            <Plan
              {...props}
              key={pack.id}
              pack={pack}
              setCurrStep={setCurrStep}
              setSelectedPack={setSelectedPack}
              currentPlan={currentPlan}
              advantages={
                pack.recurring.interval === "month"
                  ? monthlyBenifitsList
                  : yearlyBenifitsList
              }
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default AllPlans;
