import React from "react";
import { createNewAccount } from "../../../redux/slices/subscription/actions/createNewAccount";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../spinner";
import { useTranslation } from "react-i18next";
import { useWizard } from "react-use-wizard";

const RegisterUserData = ({ setActiveStep }) => {
  const { nextStep, handleStep } = useWizard();

  handleStep(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });
  const { t } = useTranslation();

  const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  const SignupSchema = () =>
    yup.object().shape({
      username: yup.string().required(`${t("Sign_up.100")}`),
      lastName: yup.string().required(t("Sign_up.101")),
      phoneNumber: yup
        .string()
        .required(t("Sign_up.102"))
        .matches(phoneRegExp, t("Sign_up.103")),
      email: yup.string().email(t("Sign_up.104")).required(t("Sign_up.105")),
      password: yup
        .string()
        .min(8, t("Sign_up.106"))
        .required(t("Sign_up.107")),
      repassword: yup
        .string()
        .required(t("Sign_up.108"))
        .oneOf([yup.ref("password")], t("Sign_up.109")),

      acceptTerms: yup.bool().oneOf([true], "Sign_in.21"),
    });
  const initialValues = {
    email: "",
    username: "",
    lastName: "",
    password: "",
    repassword: "",
    phoneNumber: "",
    policy: false,
    acceptTerms: false,
  };

  const { subLoading } = useSelector((state) => state.subscription);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: SignupSchema,
    onSubmit: async (values) => {
      await dispatch(
        createNewAccount(
          {
            email: values.email,
            username: `${values.username} ${values.lastName}`,
            password: values.password,
            repassword: values.repassword,
            phoneNumber: values.phoneNumber,
            policy: values.policy,
            acceptTerms: values.acceptTerms,
          },
          nextStep,
          setActiveStep
        )
      );
    },
  });

  return (
    <>
      {subLoading && <Spinner />}
      <form onSubmit={formik.handleSubmit}>
        <div>
          <div className="mb-3 mt-3">
            <h3 className="h5 mb-1 text-black font-weight-medium">
              {t("Sign_up.96")}
            </h3>
          </div>
          <div className="mb-3 row">
            <div className="col-6" style={{ gap: "0.8rem" }}>
              <input
                className="form-control flex-fill choiceOffer_input"
                name="username"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.username}
                placeholder={t("Sign_up.99")}
              />
              {formik.errors.username && formik.touched.username && (
                <div className="text-danger mt-1 ml-2">
                  {formik.errors.username}
                </div>
              )}
            </div>
            <div className="col-6" style={{ gap: "0.8rem" }}>
              <input
                className="form-control flex-fill choiceOffer_input"
                name="lastName"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                placeholder={t("Sign_up.98")}
              />
              {formik.errors.lastName && formik.touched.lastName && (
                <div className="text-danger mt-1 ml-2">
                  {formik.errors.lastName}
                </div>
              )}
            </div>
          </div>
          <div className="mb-3">
            <label>{t("Sign_up.24")}</label>
            <input
              name="email"
              className="form-control choiceOffer_input"
              type="email"
              data-deltad-checkmail="1"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
            />
            {formik.errors.email && formik.touched.email && (
              <div className="text-danger mt-1 ml-2">{formik.errors.email}</div>
            )}
          </div>
          <div className="mb-3">
            <label>{t("Sign_up.26")}</label>
            <input
              className="form-control choiceOffer_input"
              name="phoneNumber"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phoneNumber}
            />
            {formik.errors.phoneNumber && formik.touched.phoneNumber && (
              <div className="text-danger mt-1 ml-2">
                {formik.errors.phoneNumber}
              </div>
            )}
          </div>

          <div className="mb-3 mt-5">
            <h3 className="h5 mb-1 text-black font-weight-medium">
              {t("Sign_up.97")}
            </h3>
          </div>

          <div className="mb-3">
            <label>{t("Sign_up.27")}</label>
            <input
              name="password"
              className="form-control choiceOffer_input"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            {formik.errors.password && formik.touched.password && (
              <div className="text-danger mt-1 ml-2">
                {formik.errors.password}
              </div>
            )}
          </div>

          <div className="mb-3">
            <label>{t("Sign_up.28")}</label>
            <input
              name="repassword"
              className="form-control choiceOffer_input"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.repassword}
            />
            {formik.errors.repassword && formik.touched.repassword && (
              <div className="text-danger mt-1 ml-2">
                {formik.errors.repassword}
              </div>
            )}
          </div>
          <div className="d-flex mt-4">
            <label className="checkbox checkbox-outline-primary mb-0">
              <input
                type="checkbox"
                name="acceptTerms"
                id="acceptTerms"
                onChange={formik.handleChange}
                checked={formik.values.acceptTerms}
                className={
                  "" +
                  (formik.errors.acceptTerms && formik.touched.acceptTerms
                    ? " is-invalid"
                    : "")
                }
              />
              <span className="checkmark"></span>
            </label>
            <label className="mb-0 text-13" htmlFor="acceptTerms">
              {t("Sign_in.13")}{" "}
              <span
                style={{
                  color: "#663399",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => {
                  window.open(
                    `${window.location.origin}/dataPolicy/false?newTab=true`,
                    "_blank"
                  );
                }}
              >
                {t("Sign_in.14")}
              </span>
            </label>
          </div>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className="mt-1 ml-2">
              <small className="text-danger">
                {t(formik.errors.acceptTerms)}
              </small>
            </div>
          )}

          <button
            className="btn w-100 btn-lg btn-primary rounded-xl mt-3"
            type="submit"
          >
            {subLoading ? (
              <span>{t("Sign_up.7")}...</span>
            ) : (
              <span>{t("Sign_up.8")}</span>
            )}
          </button>
        </div>
      </form>
    </>
  );
};

export default RegisterUserData;
