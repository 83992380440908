import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import ActionButton from "../ActionsButton/ActionButton";
import addContact from "../../utils/addContact";
import { PushToZoho } from "../../../../utils/pushToZoho";
import swal from "sweetalert2";
import moment from "moment";
import { useDispatch } from "react-redux";
import verifyAbilityToAccept from "../../utils/verifyAbilityToAccept";

const Contact = ({
  contact,
  accepted,
  profile,
  refreshUserData,
  setLoading,
  prospect,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const reformulateAdress = useCallback((address, accepted) => {
    let newAddress = address;
    if (!accepted) {
      newAddress = address.replace(/^[0-9]+/g, "");
    }
    return newAddress;
  }, []);

  const alertAddLead = useCallback(() => {
    swal.fire({
      titleText: t("Leads.125"),
      showCancelButton: false,
      confirmButtonText: "OK",
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !swal.isLoading(),
    });
  }, [t]);

  const addContactFunc = useCallback(
    async (e, contact) => {
      e.stopPropagation();
      const dejaAcceptedAContact = profile?.dejaAcceptedAContact >= 2;

      if (
        verifyAbilityToAccept(
          profile?.dejaAcceptedAContact,
          profile?.lastAcceptedContactDate
        )
      ) {
        swal.fire({
          titleText: dejaAcceptedAContact ? t("Leads.128") : t("Leads.114"),
          showCancelButton: false,
          confirmButtonText: "OK",
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !swal.isLoading(),
        });
        return;
      } else {
        const addingContact = await addContact(
          contact,
          setLoading,
          dispatch,
          profile
        );
        if (addingContact?.error) {
          await swal.fire({
            titleText: t("Leads.121"),
            showCancelButton: false,
            confirmButtonText: "Refresh",
            showLoaderOnConfirm: true,
            preConfirm: () => {
              refreshUserData();
            },
            allowOutsideClick: () => !swal.isLoading(),
          });
        } else {
          setLoading(true);
          const dateAccepted = new Date();
          const [month, day, year] = [
            ("0" + (dateAccepted.getMonth() + 1)).slice(-2),
            ("0" + dateAccepted.getDate()).slice(-2),
            dateAccepted.getFullYear(),
          ];
          const [hour, minutes, seconds] = [
            ("0" + dateAccepted.getHours()).slice(-2),
            ("0" + dateAccepted.getMinutes()).slice(-2),
            ("0" + dateAccepted.getSeconds()).slice(-2),
          ];
          const json_data = {
            info: {
              data: [
                {
                  Broker_Full_Name: profile?.username,
                  Broker_Phone: profile?.phoneNumber,
                  Email: profile?.email,
                  Name: contact?.leadEmail,
                  Broker_License_Number: profile?.licenseId,
                  Date_Event: `${year}-${month}-${day}T${hour}:${minutes}:${seconds}-04:00`,
                  Statut_Event: "New Prospect",
                },
              ],
            },
            vmodule: "Brokers_Leads",
          };
          if (process.env.REACT_APP_PROJECT_ID !== "ziaapp-dev") {
            try {
              await PushToZoho(json_data);
            } catch (error) {
              console.log(error);
            }
          }
          alertAddLead();
          setLoading(false);
        }
      }
    },
    [profile, setLoading, dispatch, refreshUserData, t, alertAddLead]
  );

  return (
    <div className="newLeads_contact">
      {contact?.type === "contact" ? (
        <>
          {accepted ? (
            <>
              <div className="newLeads_contact_first">
                <div>
                  <span>{t("Leads.70")}</span>
                  <span>{contact.name}</span>
                </div>
                <div>
                  <span>{t("Leads.71")}</span>
                  <span>{contact.phone}</span>
                </div>
                <div>
                  <span>{t("Leads.72")}</span>
                  <span>{contact.email}</span>
                </div>
              </div>
              <div className="newLeads_contact_first">
                <div>
                  <span>{t("Leads.74")}</span>
                  <span>{contact.call}</span>
                </div>
              </div>
            </>
          ) : (
            <>
              {!(prospect?.broker?.length > 0) && (
                <div className="newLeads_contact_contact_notaccepted">
                  <p>{t("Leads.118")}</p>

                  <ActionButton
                    text={t("Leads.101")}
                    onClick={(e) => addContactFunc(e, prospect)}
                    icon="plus"
                  />
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <div className="newLeads_contact_first">
          <p>{t("Leads.103")}</p>
        </div>
      )}

      <div className="newLeads_contact_first">
        <div>
          <span>{t("Leads.73")}</span>
          <span>{reformulateAdress(contact.address, accepted)}</span>
        </div>
      </div>
    </div>
  );
};

export default Contact;
