import { useState, useEffect, useMemo } from "react";
import "../../packages.scss";
import { useDispatch, useSelector } from "react-redux";
import { setPlan } from "../../../../redux/slices/subscription/subscriptionSlice";
import { useTranslation } from "react-i18next";
import Spinner from "../../../spinner";
import { Container, Col, Row } from "react-bootstrap";
import { TiTick } from "react-icons/ti";

const PacksSelection = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const plans = useSelector((state) => state.subscription.plans);

  const monthlyBenifitsList = useMemo(() => {
    return [
      "Sign_up.benefits.monthly.1",
      "Sign_up.benefits.monthly.2",
      "Sign_up.benefits.monthly.3",
    ];
  }, []);

  const yearlyBenifitsList = useMemo(() => {
    return [
      "Sign_up.benefits.yearly.1",
      "Sign_up.benefits.yearly.2",
      "Sign_up.benefits.yearly.3",
      "Sign_up.benefits.yearly.4",
      "Sign_up.benefits.yearly.5",
    ];
  }, []);

  const [selectedPackage, setSelectedPackage] = useState(null);
  const [performancePlans, setPerformancePlans] = useState([]);

  useEffect(() => {
    const planList = [];
    if (plans) {
      if (plans.month) {
        const pOne = plans.month.filter((item) => item?.name === "Performance");
        planList.push(pOne[0]);
      }
      if (plans.year) {
        const pTwo = plans.year.filter((item) => item?.name === "Performance");
        planList.push(pTwo[0]);
      }
    }
    if (planList.length > 0) {
      setPerformancePlans(planList);
    }
  }, [plans]);

  useEffect(() => {
    if (performancePlans) {
      setSelectedPackage(performancePlans[1]);
    }
  }, [performancePlans]);

  useEffect(() => {
    if (selectedPackage) {
      dispatch(setPlan(selectedPackage));
    }
  }, [selectedPackage, dispatch]);

  const newTarifsView = () => (
    <div>
      {selectedPackage ? (
        <Container
          style={{
            marginBottom: "20px",
          }}
        >
          <Row className="justify-content-between gx-3">
            {performancePlans.map((item) => (
              <Col key={item?.id} xs={12} md={6} className="tarif_card">
                <div className="tarif_card_inner_container">
                  {item?.interval === "year" && (
                    <div className="tarif_card_banner">{t("Sign_up.127")}</div>
                  )}
                  <div
                    className="bg-violet bg-cover d-flex flex-column justify-content-center align-items-center"
                    style={{
                      height: "140px",
                      width: "100%",
                      backgroundImage: `${
                        selectedPackage?.id === item?.id
                          ? `url(/assets/packages/bg-p.svg)`
                          : ""
                      }`,
                    }}
                  >
                    <div className="step-icon">
                      <img
                        src={
                          item?.metadata.find(
                            (el) => el.key === `${item?.interval}_img`
                          )?.value
                        }
                        alt="pack-icon"
                      />
                    </div>

                    <h4 className="text-white font-weight-bold text-capitalize mt-3">
                      {t(`Sign_up.${item.name} - ${item.interval}`)}
                    </h4>
                  </div>
                  <div className="tarif_card_price_container">
                    <span className="tarif_card_price">
                      {new Intl.NumberFormat("en-CA", {
                        style: "currency",
                        currency: "CAD",
                        maximumFractionDigits: 2,
                      }).format(item?.amount / 100)}{" "}
                    </span>
                    <span className="tarif_span_interval">
                      {" "}
                      {item?.interval === "year"
                        ? ` /${t("Sign_up.yearUnit")} (-15%)`
                        : ` /${t("Sign_up.monthUnit")}`}
                    </span>
                  </div>
                  <div className="tarif_card_list">
                    <ul>
                      {item?.interval === "month"
                        ? monthlyBenifitsList.map((el) => {
                            return (
                              <li key={el} className="d-flex align-items-start">
                                <TiTick className="mr-2 mt-1 flex-shrink-0" />
                                <span>{t(`${el}`)}</span>
                              </li>
                            );
                          })
                        : yearlyBenifitsList.map((el) => {
                            return (
                              <li key={el} className="d-flex align-items-start">
                                <TiTick className="mr-2 mt-1 flex-shrink-0" />
                                <span>{t(`${el}`)}</span>
                              </li>
                            );
                          })}
                    </ul>
                  </div>
                  <div
                    className={`${
                      selectedPackage?.id === item?.id
                        ? "tarif_card_button_selected"
                        : "tarif_card_button"
                    }`}
                  >
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        setSelectedPackage(item);
                        // scrollToNextStepButton();
                      }}
                    >
                      {item?.id === selectedPackage?.id
                        ? t("Sign_up.129")
                        : t("Sign_up.128")}
                    </button>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      ) : (
        <Spinner />
      )}
    </div>
  );

  return <div>{newTarifsView()}</div>;
};

export default PacksSelection;
