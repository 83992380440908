import moment from "moment";
import { useTranslation } from "react-i18next";

const SubscriptionHistory = ({ transactions }) => {
  const { t } = useTranslation();

  return (
    <div className="card-container subscription-history-container">
      <p className="component-title component-title-margin">
        {t("profile.subscription.transactions.8")}
      </p>
      <div className="ul-widget6__item--table ">
        <table className="table table-responsive">
          <thead>
            <tr className="ul-widget6__tr--sticky-th">
              <th scope="col" className="border-0">
                {t("profile.subscription.transactions.1")}
              </th>
              <th scope="col" className="border-0">
                {t("profile.subscription.transactions.2")}
              </th>
              <th scope="col" className="border-0">
                {t("profile.subscription.transactions.3")}
              </th>
              <th scope="col" className="border-0">
                {t("profile.subscription.transactions.4")}
              </th>
              <th scope="col" className="border-0">
                {t("profile.subscription.transactions.5")}
              </th>
              <th scope="col" className="border-0"></th>
            </tr>
          </thead>
          <tbody>
            {transactions?.length &&
              transactions.map((transaction, key) => (
                <tr key={key}>
                  <td className="table-data">
                    {`${t("profile.subscription.transactions.6")}${
                      transaction.id
                    }`}
                  </td>
                  <td className="table-data">
                    <p
                      className={
                        transaction.paid === true
                          ? "transaction-status"
                          : "transaction-rejected"
                      }
                    >
                      {transaction.paid === true ? "Payé" : "Rechazada"}
                    </p>
                  </td>
                  <td className="table-data">
                    {moment(transaction.created * 1000).format("MMM Do YY")}
                  </td>
                  <td className="table-data">
                    <p className="plan">{transaction.description}</p>
                  </td>
                  <td className="table-data">
                    {(transaction.amount_captured / 100)
                      .toFixed()
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                  <td className=" border-0">
                    <button
                      className="non-filled-bg-btn"
                      onClick={() =>
                        window.open(transaction.receipt_url, "_blank")
                      }
                    >
                      {t("profile.subscription.transactions.7")}
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SubscriptionHistory;
