import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const WarningMessage = ({ accountData }) => {
  const { t } = useTranslation();
  return (
    <>
      {accountData?.subscriptionStatus === "past_due" && (
        <div className="header-warning-text">
          <div>
            <span>{t("Header.12")}</span>
            <Link to="/abonnement" className="header-warning-text_link">
              <span className="ml-1">{t("Header.13")}</span>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default WarningMessage;
