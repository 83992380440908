import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { extractMunicipalitiesFromFilterSummary } from "../utils/extractMunicipalitiesFromFilterSummary";
import swal from "sweetalert2";

const Summary = ({ munciValue, filterSummary, updateFilterSummary }) => {
  const { t } = useTranslation();

  const [showSummary, setShowSummary] = useState(false);

  const handleMunicipalRemove = useCallback(
    async (municip) => {
      const municipalities =
        extractMunicipalitiesFromFilterSummary(filterSummary);

      if (municipalities.length === 1) {
        await swal.fire({
          titleText: t("Leads.123"),
          showCancelButton: false,
          confirmButtonText: "Okay",
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !swal.isLoading(),
        });
        return;
      }

      const munipCity = Object.keys(filterSummary).find((city) =>
        filterSummary[city].find((m) => m.value === municip.value)
      );

      if (municip && munipCity) {
        await updateFilterSummary(municip, {
          label: munipCity,
          value: munipCity,
        });
      }
    },
    [filterSummary, updateFilterSummary, t]
  );

  const formatFilterSummary = useCallback(
    (filterSummary) => {
      const lines = [];
      for (const [key, value] of Object.entries(filterSummary)) {
        lines.push(
          <div key={key} className="newleads_filter_summary_line">
            <span className="font-weight-bold mr-2">{key}:</span>
            {value.map((v) => (
              <span
                key={v.label}
                className="newleads_filter_summary_municip"
                title={t("Leads.127")}
              >
                <span>{v.label}</span>
                <IoIosRemoveCircleOutline
                  className="newleads_filter_summary_municip_remove"
                  onClick={() => handleMunicipalRemove(v)}
                />
              </span>
            ))}
          </div>
        );
      }
      const jsx = <div className="newleads_filter_summary_lines">{lines}</div>;
      return jsx;
    },
    [handleMunicipalRemove, t]
  );

  return (
    <div className="px-2 newleads_filter_summary">
      <div className="newleads_filter_summary_head">
        <h4 className="row">{t("Leads.102")}:</h4>
        {showSummary ? (
          <img
            src="assets/icons/upArrow.svg"
            alt="hide"
            className="newleads_filter_summary_img"
            onClick={() => setShowSummary(false)}
          />
        ) : (
          <img
            src="assets/icons/downArrow.svg"
            alt="show"
            className="newleads_filter_summary_img"
            onClick={() => setShowSummary(true)}
          />
        )}
      </div>
      {showSummary && (
        <>
          {filterSummary ? (
            <div className="row">{formatFilterSummary(filterSummary)}</div>
          ) : (
            <div className="row">
              {munciValue?.map((v) => (
                <div key={v.label} className="newleads_filter_summary_municip">
                  {v.label}
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Summary;
