import PacksSelection from "../components/packsSelection";
import { useWizard } from "react-use-wizard";
import { useTranslation } from "react-i18next";

const PlanSelect = ({ setActiveStep }) => {
  const { t } = useTranslation();
  const { nextStep } = useWizard();

  return (
    <>
      <PacksSelection />
      <button
        className="btn w-100 btn-lg btn-primary rounded-xl mt-3"
        id="next-step"
        onClick={() => {
          nextStep();
          setActiveStep(3);
        }}
      >
        <span>{t("Sign_up.130")}</span>
      </button>
    </>
  );
};

export default PlanSelect;
