import { useCallback } from "react";
import { Dropdown } from "react-bootstrap";

import { Link } from "react-router-dom";

import { updateLeftSideBarOpen } from "../../../redux/slices/layout/actions/UpdateLeftSideBarOpen";
import { useSelector, useDispatch } from "react-redux";
import { changeLanguage } from "i18next";
import { useTranslation } from "react-i18next";
import { ReactComponent as Deconnecter } from "./menuIcons/deconnecter.svg";
import { logOut } from "../../../redux/slices/authentication/actions/logout";

const Header = () => {
  const dispatch = useDispatch();
  const { leftSidebar } = useSelector((state) => state.layoutSetting);
  const { userData } = useSelector((state) => state.authentication);

  const { t } = useTranslation();

  const handleMenuClick = useCallback(() => {
    dispatch(updateLeftSideBarOpen(!leftSidebar.open));
  }, [dispatch, leftSidebar.open]);

  const handleLanguage = useCallback((lang) => {
    changeLanguage(lang);
  }, []);

  return (
    <div className="main-header px-0">
      <div className="logo">
        <img src="/assets/images/logo.png" alt="" />
      </div>

      <div className="menu-toggle" onClick={handleMenuClick}>
        <div></div>
        <div></div>
        <div></div>
      </div>

      <div className="d-none d-lg-flex align-items-center">
        <Link className="pl-3 text-16" to="/">
          <i className="i-Home1 pr-2"></i>
          {t("Header.1")}
        </Link>
        <a
          className="pl-3 text-16"
          target="_blank"
          rel="noopener noreferrer"
          href="https://pro.ziaway.ca/blogs/"
        >
          <span className="pl-2 pr-2"></span>
          <i className="i-Newspaper pr-2"></i>
          {t("Header.2")}
        </a>
        <Link className="pl-3 text-16" to="/moncompte">
          <i className="i-Administrator pr-2"></i>
          {t("Header.6")}
        </Link>
      </div>

      <div style={{ margin: "auto" }}></div>

      <div className="header-part-right">
        <div className="d-none d-lg-block align-self-end">
          <Link
            to="#"
            className="dropdown-item cursor-pointer bg-primary rounded"
          >
            <span className="text-16 text-white text-bold text-capitalize">
              {t("Header.5")} {userData && userData?.displayName}
            </span>
          </Link>
        </div>
        <Dropdown>
          <Dropdown.Toggle
            variant="link"
            id="dropdown-basic"
            className="d-flex align-items-center"
          >
            <img
              className="d-block mr-1"
              height={15}
              width={22}
              src="/assets/flags/canada.png"
              alt=".."
            />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleLanguage("fr")}>
              <div className="d-flex align-items-center">
                <span>{t("Header.10")}</span>
              </div>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleLanguage("en")}>
              <div className="d-flex align-items-center">
                <span>{t("Header.11")}</span>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <div title={t("Header.4")}>
          <Deconnecter
            className="nav-icon"
            onClick={() => dispatch(logOut())}
            style={{
              height: "1.5rem",
              width: "1.5rem",
              stroke: "#2B2B2B",
              marginRight: "1rem",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
