import { useTranslation } from "react-i18next";
import swal from "sweetalert2";
import { PushToZoho } from "../../../../utils/pushToZoho";
import ActionButton from "./ActionButton";
import addBookmark from "../../utils/addBookmark";
import removeBookmark from "../../utils/removeBookmark";
import addContact from "../../utils/addContact";
import removeContact from "../../utils/removeContact";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useCallback } from "react";
import verifyAbilityToAccept from "../../utils/verifyAbilityToAccept";

const ActionsButton = ({
  prospect,
  profile,
  setLoading,
  refreshUserData,
  setSelectedProspect,
  loading,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const checkBookmarks = useCallback(
    (id) => {
      if (profile?.bookmarks?.some((person) => person?.id === id)) {
        return true;
      } else {
        return false;
      }
    },
    [profile]
  );

  const alertAddLead = useCallback(() => {
    swal.fire({
      titleText: t("Leads.125"),
      showCancelButton: false,
      confirmButtonText: "OK",
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !swal.isLoading(),
    });
  }, [t]);

  const atertRemoveLead = useCallback(() => {
    swal.fire({
      titleText: t("Leads.126"),
      showCancelButton: false,
      confirmButtonText: "Ok",
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !swal.isLoading(),
    });
  }, [t]);

  const addContactFunc = useCallback(
    async (e, contact) => {
      e.stopPropagation();
      const dejaAcceptedAContact = profile?.dejaAcceptedAContact >= 2;

      if (
        verifyAbilityToAccept(
          profile?.dejaAcceptedAContact,
          profile?.lastAcceptedContactDate
        )
      ) {
        swal.fire({
          titleText: dejaAcceptedAContact ? t("Leads.128") : t("Leads.114"),
          showCancelButton: false,
          confirmButtonText: "OK",
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !swal.isLoading(),
        });
        return;
      } else {
        const addingContact = await addContact(
          contact,
          setLoading,
          dispatch,
          profile
        );
        if (addingContact?.error) {
          await swal.fire({
            titleText: t("Leads.121"),
            showCancelButton: false,
            confirmButtonText: "Refresh",
            showLoaderOnConfirm: true,
            preConfirm: () => {
              refreshUserData();
            },
            allowOutsideClick: () => !swal.isLoading(),
          });
        } else {
          setLoading(true);
          const dateAccepted = new Date();
          const [month, day, year] = [
            ("0" + (dateAccepted.getMonth() + 1)).slice(-2),
            ("0" + dateAccepted.getDate()).slice(-2),
            dateAccepted.getFullYear(),
          ];
          const [hour, minutes, seconds] = [
            ("0" + dateAccepted.getHours()).slice(-2),
            ("0" + dateAccepted.getMinutes()).slice(-2),
            ("0" + dateAccepted.getSeconds()).slice(-2),
          ];
          const json_data = {
            info: {
              data: [
                {
                  Broker_Full_Name: profile?.username,
                  Broker_Phone: profile?.phoneNumber,
                  Email: profile?.email,
                  Name: prospect?.userEmail,
                  Broker_License_Number: profile?.licenseId,
                  Date_Event: `${year}-${month}-${day}T${hour}:${minutes}:${seconds}-04:00`,
                  Statut_Event: "New Prospect",
                },
              ],
            },
            vmodule: "Brokers_Leads",
          };
          if (process.env.REACT_APP_PROJECT_ID !== "ziaapp-dev") {
            try {
              await PushToZoho(json_data);
            } catch (error) {
              console.log(error);
            }
          }
          alertAddLead();
          setLoading(false);
        }
      }
    },
    [alertAddLead, dispatch, profile, prospect, refreshUserData, setLoading, t]
  );

  const removeContactFunc = useCallback(
    async (e, contact, dispatch, profile) => {
      e.stopPropagation();
      await removeContact(contact, setLoading, dispatch, profile);
      await setSelectedProspect(null);
      atertRemoveLead();
    },
    [atertRemoveLead, setSelectedProspect, setLoading]
  );

  return (
    <>
      <div>
        {prospect.type === "prospect" && (
          <>
            <div>
              {checkBookmarks(prospect?.id) === true ? (
                <ActionButton
                  text={t("Leads.98")}
                  onClick={(e) =>
                    removeBookmark(
                      e,
                      prospect,
                      setLoading,
                      dispatch,
                      profile,
                      atertRemoveLead
                    )
                  }
                  icon="remove"
                />
              ) : (
                <ActionButton
                  text={t("Leads.101")}
                  onClick={(e) =>
                    addBookmark(
                      e,
                      prospect,
                      setLoading,
                      dispatch,
                      profile,
                      alertAddLead
                    )
                  }
                  icon="plus"
                />
              )}
            </div>
          </>
        )}

        {prospect.type === "contact" && (
          <>
            {prospect?.broker?.length > 0 &&
            prospect?.broker[0]?.brokerId !== profile.userId ? (
              <div>
                <ActionButton
                  cn="newLeads_prospects_list_item_actionType_selected"
                  text={t("Leads.113")}
                />
              </div>
            ) : (
              <div>
                {checkBookmarks(prospect?.id) === true ? (
                  <div>
                    <ActionButton
                      text={t("Leads.98")}
                      onClick={(e) =>
                        removeContactFunc(e, prospect, dispatch, profile)
                      }
                      icon="remove"
                    />
                  </div>
                ) : (
                  <div className="avaliableMenu">
                    <div>
                      <ActionButton
                        text={t("Leads.101")}
                        onClick={(e) =>
                          addContactFunc(e, prospect, dispatch, profile)
                        }
                        icon="plus"
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ActionsButton;
